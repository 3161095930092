import { useCallback, useEffect, useRef, useState } from 'react'
import s from './styles.module.scss'
import * as api from 'api'
import { Alert, Filter, GoogleMap } from 'components'
import { useSelector } from 'react-redux'
import { Encrypt } from 'helpers'

const mapFilter = [
  {
    field: 'All'
  },
  {
    field: 'Businesses'
  },
  {
    field: 'Users'
  }
]

export default function Main() {
  const [loading, setLoading] = useState(false)
  const [userBusinessLocations, setUserBusinessLocations] = useState([])
  const [usersLocations, setUserLocations] = useState([])
  const [mapLocations, setMapLocations] = useState([])
  const [defaultCoordinates, setDefaultCoordinates] = useState(null)
  const location = useSelector(state => state.location).location
  const [selectedFilterField, setSelectedFilterField] = useState(mapFilter[0].field)
  const processing = useRef(false)
  const processing1 = useRef(false)

  const filterHandler = field => {
    setSelectedFilterField(field)
    if (field === 'Businesses') setMapLocations([...userBusinessLocations])
    if (field === 'Users') setMapLocations([...usersLocations])
    if (field === 'All') setMapLocations([...userBusinessLocations, ...usersLocations])
  }

  const getLocations = useCallback(async () => {
    if (processing.current) return
    processing.current = true
    setLoading(true)

    const fetchBusinessLocations = await api.publicApi.business.fetchBusinessLocations({})

    let locationsFetched = []

    if (fetchBusinessLocations.code === 200) {
      const newBusinessLocations = fetchBusinessLocations.payload.getBusinessLocations.map(business => ({
        ...business,
        isUser: false,
        link: '/' + business.city + '/' + Encrypt({ fkBusinessId: business._id })
      }))

      setUserBusinessLocations(newBusinessLocations)
      locationsFetched = [...locationsFetched, ...newBusinessLocations]
    } else {
      Alert.error(fetchBusinessLocations.message)
    }

    const fetchUsersLocations = await api.publicApi.user.fetchUsersLocations({})

    if (fetchUsersLocations.code === 200) {
      const newUserLocations = fetchUsersLocations.payload.getUsersLocations.map(users => ({
        ...users,
        isUser: true,
        subCategory: '',
        businessName: users.firstName + ' ' + users.lastName,
        images: [users.logo],
        link: '/userProfile/' + Encrypt({ fkUserId: users._id })
      }))

      setUserLocations(newUserLocations)
      locationsFetched = [...locationsFetched, ...newUserLocations]
    } else {
      Alert.error(fetchUsersLocations.message)
    }

    setMapLocations(locationsFetched)

    setLoading(false)
    processing.current = false
  }, [])

  useEffect(() => {
    getLocations()
  }, [getLocations])

  const getCoordinatesViaCity = useCallback(async () => {
    if (processing1.current) return
    processing1.current = true
    setLoading(true)

    const matchingCityCoordinates = await api.publicApi.locations.fetchCoordinatesViaCity({ city: location })

    if (matchingCityCoordinates.code === 200) {
      setDefaultCoordinates(matchingCityCoordinates.payload)
    } else {
      Alert.error(matchingCityCoordinates.message)
    }

    setLoading(false)
    processing1.current = false
  }, [location])

  useEffect(() => {
    if (location !== 'All Over India') getCoordinatesViaCity()
    else {
      setDefaultCoordinates(null)
    }
  }, [location, getCoordinatesViaCity])

  return (
    !loading && (
      <div className={s.main}>
        <div className={s.locationsOuter}>
          <div className={s.Locations + ' indent'}>
            <div className={s.title}>Registered Users</div>
            <div className={s.filter}>
              <Filter
                title={selectedFilterField}
                heading={selectedFilterField}
                filterFields={mapFilter}
                filterHandler={filterHandler}
                style2
              />
            </div>
            <div className={s.locationsBox}>
              <GoogleMap.MultipleLocations defaultLocation={defaultCoordinates} userBusinessLocations={mapLocations} />
            </div>
          </div>
        </div>
      </div>
    )
  )
}
